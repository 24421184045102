<template>
 <v-app id="inspire">
  <v-sheet color="lighten-5" height="600" align="center" justify="center">
    <v-container>
        <img
            :src="bucketUrl + authenticatedUser.picture_url"
            alt=""
            width="150"
            class="mx-auto d-block mt-5 mb-5 rounded-square picture-thumbnail"
            v-if="authenticatedUser && authenticatedUser.picture_url"
        >
        <img
            src="images/profile_male.png"
            alt=""
            v-if="authenticatedUser && !authenticatedUser.picture_url && (authenticatedUser.gender === 'Male'||authenticatedUser.string_gender === 'Male')"
        >
        <img
            src="images/profile_female.png"
            alt=""
            v-if="authenticatedUser && !authenticatedUser.picture_url && (authenticatedUser.gender === 'Female'||authenticatedUser.string_gender === 'Female')"
        >
        <div v-if="authenticatedUser">
            <v-list-item two-line>
            <v-list-item-content v-if="authenticatedUser">
                <v-list-item-title class="headline black--text"> {{authenticatedUser.firstname}} {{authenticatedUser.middlename ? authenticatedUser.middlename : ''}} {{authenticatedUser.lastname}}</v-list-item-title>
                <v-list-item-title class="black--text">Identity Number:  {{authenticatedUser.student_number}}</v-list-item-title>
                <v-list-item-title class="black--text" v-if="!authenticatedUser.institute_id"> {{ authenticatedUser.department.name }}</v-list-item-title>
                <v-list-item-title class="black--text">{{ current_level }}, {{ authenticatedUser.programme ? authenticatedUser.programme.name : '' }}</v-list-item-title>
                <v-list-item-title class="black--text"><b>Status: {{ student_status }}</b></v-list-item-title>
                <v-list-item-title class="black--text" v-show="currentAcademicSession !== null"><b>Current Session: {{ currentSessionDisplayMessage }}</b></v-list-item-title>
                <v-list-item-title class="black--text" v-show="currentAcademicSession !== null"><b>Current Semester: {{ currentSemesterDisplayMessage }}</b></v-list-item-title>

                        </v-list-item-content>
                    </v-list-item>
                    <router-link :to="{ name: 'Student Profile', params: { user: student_data } }">
                        <v-btn class="mt-5 submit-btn" depressed> View Profile </v-btn>
                    </router-link>
                </div>
                <v-main v-if="student_status === 'Graduated'" style="padding:0 !important;">
                    <CongratulationsAlert
                        :student_name="student_data.fullname"
                        :class_of_degree="student_data.class_of_degree"
                        v-if="student_status === 'Graduated'"
                    />
                </v-main>

                <div v-if="authenticatedUser && !authenticatedUser.picture_url">
                    <v-card flat class="mt-5 px-5 pb-n10" width="850" color="darken-2">
                        <marquee behavior="alternate" direction="left" scrollamount="5"
                            style="color:red; font-size: 20px; font-weight: bold; margin-top: 10px; margin-bottom: 10px;">
                            <span class="text-center pb-n2 pt-2 font-weight-bold">Click on view profile to update your
                                passport</span>
                        </marquee>
                    </v-card>
                </div>
                <div v-if="authenticatedUser && highPriorityAnnouncement">
                    <v-card flat class="mt-5 px-5 pb-n10" width="850" color="darken-2">
                        <marquee behavior="alternate" direction="left" scrollamount="5"
                            style="color:red; font-size: 20px; font-weight: bold; margin-top: 10px; margin-bottom: 10px;">
                            <span class="text-center pb-n2 pt-2 font-weight-bold">{{ announcement_list[0].message }}</span>
                        </marquee>
                    </v-card>
                </div>
                <!-- <div v-if="authenticatedUser && has_incomplete_transfer_requeset">
                    <v-card flat class="mt-5 px-5 pb-n10" width="850" color="darken-2">
                        <marquee behavior="alternate" direction="left" scrollamount="5"
                            style="color:red; font-size: 20px; font-weight: bold; margin-top: 10px; margin-bottom: 10px;">
                            <span class="text-center pb-n2 pt-2 font-weight-bold">Please visit your recently submitted
                                <b>Transfer request</b> and re-upload your o-level and utme files as soon as possible.
                            </span>
                        </marquee>
                    </v-card>
                </div> -->
                <!-- overiview cards component i. lectures, courses, Assignments -->
                <OverviewCards></OverviewCards>
            </v-container>
            <v-container>
                <v-row>
                    <v-col>
                        <v-card outlined elevation="2" light height="290">
                            <v-card-title style="font-family: 'Poppins',sans-serif!important;">Upcoming
                                Events</v-card-title>
                            <!-- <h1 class="text-left px-5">Course Registration Deadline: 29th August</h1> -->

                            <v-card-actions class="justify-end py-5">
                                <v-row align="center" justify="end">
                                    <v-btn class="mt-5 mr-10" depressed color="primary">
                                        View Calender
                                    </v-btn>
                                </v-row>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                    <v-col>
                        <v-card outlined elevation="2" color="#ffffff" light height="290">
                            <v-card-title color="primary" style=" font-family: 'Poppins',sans-serif!important;">My Time
                                Table</v-card-title> <!--Next Lecture-->

                            <!--        <h1 class="text-left pl-5">08:00:00 AM</h1> -->

                            <v-card-actions class="justify-end py-5">
                                <v-row align="center" justify="end">
                                    <v-btn class="mr-10" style="margin-top:4.5em" depressed color="primary"
                                        @click="switchComponent('Student Time Table')">
                                        View Timetable
                                    </v-btn>
                                </v-row>
                            </v-card-actions>

                        </v-card>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <!--   <v-card outlined elevation="2" color="#ffffff" light style=" font-family: Poppins;">
                            <v-card-title color="primary" class="font-weight-bold" >School Resumption</v-card-title>

                                <p class="text-left px-6 font-weight-medium body-1">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ipsum, animi maxime, ullam esse quis necessitatibus fuga voluptas, dignissimos officiis provident sit temporibus eius eaque enim. Ullam doloremque consectetur odit maiores?</p>
                                 <h1 class="text-left pa-3">08:00:00 AM</h1>

                                <v-divider class="px-8" inset></v-divider>
                                 <v-card-actions class="justify-end py-5">
                                <v-card-text class="text-left">Posted By: Some Guy</v-card-text>
                                <v-spacer></v-spacer>
                               <v-card-text class="text-right">3 Days Ago</v-card-text>
                            </v-card-actions>
                            </v-card> -->
                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>
    </v-app>
</template>

<script>
import OverviewCards from "./OverviewCards";
import AnnouncementAPI from "../../../apis/Announcement";
import AcademicSessionAPI from "../../../apis/AcademicSession";
import StudentAPI from "../../../apis/Student"
import Transfer from "../../../apis/Transfer";
import CongratulationsAlert from './CongratulationsAlert.vue';



export default {
    name: "Overview",
    components: { OverviewCards, CongratulationsAlert },

    props: {
        authenticatedUser: Object,
        student_info:Object,
    },

    data() {
        return {
            userID: 0,
            highPriorityAnnouncement: false,
            announcement_list: [],
            programme_level: null,
            current_level: null,
            userable_type: null,
            student_id: null,
            student_status: '',
            has_incomplete_transfer_requeset: false,
            currentAcademicSession: null,
            available_semesters: [],
            currentAcademicSemester: null,

            student_data: [],
        }

    },

    computed: {
        currentSessionDisplayMessage() {
        return this.currentAcademicSession
            ? this.currentAcademicSession.session_name + " Academic Session"
            : "No Active Academic Session";
        },

        currentSemesterDisplayMessage() {

            let current_semester = this.available_semesters ? this.available_semesters.find(semester => semester.status === 2) : null;
            return this.currentAcademicSession
                ? (current_semester ? current_semester.semester_setting?.title : "No Active Academic Semester")
                : "No Active Academic semester";
        },

        checkSession() {
        return this.currentAcademicSession ? true : false;
        },


  },

    methods: {
        initialize() {
            if (this.authenticatedUser) {
                this.programme_level = this.authenticatedUser.programme.academic_programme_level_id;
                this.userable_type = "Student";

                // AcademicSessionAPI.getCurrentAcademicSession(this.authenticatedUser.institute_id)
                // .then((response) => {
                //     this.currentAcademicSession = response.data;
                //     this.activeSessionExists = this.currentAcademicSession ? true : false;
                //     if(this.activeSessionExists){
                        // this.getAnnouncements(this.programme_level, this.currentAcademicSession.id, this.userable_type);
                //     }
                // })
                // .catch((error) => {
                //     console.log(error);
                // });

                // Retrieve the JSON string from localStorage
                const userJson = localStorage.getItem('user');

                // Parse the JSON string into a JavaScript object
                const userObject = JSON.parse(userJson);

                const user = userObject.auth_user;
                // Access the id property
                const id = user.id;


                    StudentAPI.getStudentByID(id)
                    .then(response => {
                        this.student_data = response.data.student;
                        this.student_id = this.student_data.id;
                        this.current_level = this.student_data.currentLevel;
                        this.student_status = this.student_data.status;
                        this.getCurrentSession(this.student_data.programme.id, this.student_data.current_level_id, this.student_data.institute_id)
                        this.getCheckIncompleteRequest();
                    });
            }
        },

        getCurrentSession(programme_id, level_id, institute_id) {
          AcademicSessionAPI.getCurrentSpecialSession(programme_id, level_id, institute_id)
              .then(response => {
                  this.currentAcademicSession = response.data;
                  this.available_semesters = this.currentAcademicSession.semesters;
                  this.getAnnouncements(this.programme_level, this.currentAcademicSession.id, this.userable_type, institute_id);
              })
              .catch(error => {
                  console.log(error)
              })
      },

        loadProfile() {
            // console.log(this.student_data);
            this.$router.push({
                name: 'Student Profile',
                params: {
                    user: this.student_data
                }
            });
        },

        getAnnouncements(programme_level_id, session_id, userable_type, institute_id) {
            AnnouncementAPI.getAnnouncements(programme_level_id, session_id, userable_type, institute_id)
                .then((response) => {
                    this.announcement_list = [];
                    for (const key in response.data.data) {
                        let announcement = response.data.data[key];
                        if (announcement.priority == 'High') {
                            this.highPriorityAnnouncement = true;
                            this.announcement_list.push(announcement);
                        }
                    }
                })
                .catch((error) => console.log(error))
                .finally(() => { this.loading = false; });
        },

        getCheckIncompleteRequest() {
            Transfer.checkIncompleteRequest(this.student_id).then(res => {
                this.has_incomplete_transfer_requeset = res.data.has_incomplete_request;
            }).catch(e => console.log(e));
        },

        switchComponent(comp) {
            this.$router.push({ name: comp });
        },
    },
    mounted() {
        this.initialize();
    },
}
</script>

<style></style>
